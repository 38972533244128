.inventory-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: stretch;
  max-height: 100%;
}

.top_to_bottom {
  flex-direction: column;
}

.inventory-grid .item-meta-primary {
  display: flex;
  flex-direction: column;
}

.inventory-grid .item-meta-primary.reversed {
  flex-direction: column-reverse;
}

.inventory-grid .item-meta-primary.dense {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.inventory-grid .item-meta-primary.dense.reversed {
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: flex-end;
}

.inventory-grid .item-meta {
  padding: 10px 0 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.inventory-grid .item-meta.large_screen {
  padding: 20px 0 20px 70px;
}

.inventory-grid .item-meta:last-child {
  margin-right: 0;
}
.inventory-grid .item-meta.sample-preview {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 82.94039%;
}
.inventory-grid .item-meta.sample-preview:last-child {
  margin-right: 0;
}
.inventory-grid .item-meta:nth-of-type(2n) {
  margin-right: 0;
}

.item-description.large_screen {
  margin-top: 10px;
  margin-bottom: 10px;
}

.inventory-grid .item-meta-description {
  /* CSS trick to keep the box from overflowing */
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.inventory-grid .item-name {
  font-size: 1.75em;
  color: white;
  font-weight: 500;
  /* CSS to add ellipsis and limit one line */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* max-height: 1.33em; */
}

.inventory-grid .item-source {
  font-size: 1.625em;
  font-family: 'Unica One', Roboto, sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
}
.inventory-grid .item-source.dense {
  font-size: 1.8em;
  margin-left: 10px;
}
.inventory-grid .item-meta-primary.reversed .item-source.dense {
  margin-left: 0;
  margin-right: 10px;
}
.inventory-grid .item-meta-cost-single.dense {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-end;
}
.inventory-grid .item-meta-cost-single.dense .item-size {
  margin-right: 10px;
  display: flex;
}
.inventory-grid .item-meta-cost-single.dense .item-size .vessel-size {
  margin-left: 5px;
}
.inventory-grid .item-meta-cost-single.dense .item-size .vessel-size span {
  font-size: 0.7em;
}

.inventory-grid .item-price {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}
.inventory-grid .item-meta-secondary,
.inventory-grid .item-size {
  font-size: 1.5em;
  line-height: 1.25;
  opacity: 0.7;
  font-family: 'Unica One', Roboto, sans-serif;
}
.inventory-grid .item-size {
  text-align: center;
  display: flex;
}
.inventory-grid .item-size .vessel-size {
  /*display: none;*/
  margin-left: 5px;
}
.inventory-grid .item-size .vessel-size span {
  font-size: 0.7em;
}
.inventory-grid .big-handle {
  font-size: 3.5em;
  opacity: 0.4;
  font-family: 'Open Sans';
  font-weight: 800;
  width: 85px;
  text-align: right;
  padding-right: 15px;
}
.inventory-grid .big-handle.large_screen {
  direction: rtl;
  font-size: 5em;
  margin-top: -10px;
  width: 100px;
  padding-right: 30px;
}

.inventory-grid .item-image {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-position: center;
  margin-right: 16px;
  background-size: cover;
}

.inventory-grid .item-image.large_screen {
  width: 110px;
  height: 110px;
  margin-right: 30px;
}

.inventory-grid .item-image.large,
.inventory-grid .item-image.large_square {
  width: 150px;
  height: 150px;
  border-radius: 150px;
}

.inventory-grid .item-image.small_square,
.inventory-grid .item-image.large_square {
  border-radius: 4px;
}
.inventory-grid .item-rating {
  position: absolute;
  left: 10px;
  top: 130px;
}

.inventory-grid .item-meta-keg-status {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 10px;
}

.inventory-grid .item-meta-keg-status .progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.inventory-grid .item-meta-keg-status .progress-bar.full {
  background-color: #73dd58;
}
.inventory-grid .item-meta-keg-status .progress-bar.mid {
  background-color: #eae02a;
}
.inventory-grid .item-meta-keg-status .progress-bar.empty {
  background-color: #f98145;
}

.inventory-grid .single-line,
.inventory-grid .single-line-large_screen {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.page-heading {
  font-size: 3.5em;
  font-weight: bold;
  opacity: 0.4;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}
.page-heading.large_screen {
  font-size: 3.8em;
  padding-top: 60px;
  padding-left: 60px;
}

.section-title {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 2rem;
  font-size: 3rem;
  color: white;
  text-transform: uppercase;
}
