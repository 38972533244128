@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,800');
@import url('https://fonts.googleapis.com/css?family=Unica+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100%;
  height: 100%;
  width: 100%;
  color: #35495d;
  margin: 0;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
  body {
    background-color: white !important;
  }
}

@media screen and (min-width: 401px) and (max-width: 599px) {
  body {
    background-color: white !important;
  }
}

@media screen and (min-width: 600px) {
  body {
    background-color: #efefef !important;
  }
}

body {
  font-family: 'Open Sans', sans-serif;
}

#root {
  height: 100%;
}

/* disable Tailwind's default outline styles */
input {
  --tw-ring-shadow: 0 0 #000 !important;
}

/* hack in order to remove blue border from mutiline material ui textfields */
.MuiInputBase-input:focus {
  box-shadow: none;
}
